import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg4 from "../assets/img/project-img4.png";
import projImg5 from "../assets/img/project-img5.png";
import projImg6 from "../assets/img/project-img6.png";
import founderImg from "../assets/img/founder-img.png";
import inspirationImg from "../assets/img/inspiration-img.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Education and College",
      description: "TheKasuneProject",
      imgUrl: projImg1,
    },
    {
      title: "Extra Tuition Fees",
      description: "Design & Development",
      imgUrl: projImg2,
    },
    {
      title: "Community Outreach",
      description: "Design & Development",
      imgUrl: projImg3,
    },
    {
      title: "Youth Mental Health",
      description: "Design & Development",
      imgUrl: projImg4,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg5,
    },
    {
      title: "Business Startup",
      description: "Design & Development",
      imgUrl: projImg6,
    },
    
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>TheKasuneProject provides access to higher education by providing funding using an equitable approach.  How funding is provided depends on the ambitions of the young person, their basic formal education, school leaving results etc. Prospective students are then supported in making goals that are realistic and achievable. TheKasuneProject supports academic or vocational education as it is important that opportunities are reflective of different abilities and ambitions. TheKasuneProject encourages families to participate on all levels in supporting their loved one (including financial support) on the agreed educational journey.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Our Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Our Story</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Our People</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis.</p>
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                      <p>The Kasune Project (TKP) is an organisation whose purpose is to provide funding for equitable access to quality education at identified levels.  The Kasune Project is a functional, cohesive organisation of committed members, who will continuously strive to improve capacity for quality service delivery and mobilise resources to address the critical issues that hinder the youth of Zambia from equitable access to a quality education, be it academic, trade or skill. Through further education, The Kasune Project seeks to empower young people realise their full potential as well as strengthen their capacity to achieve sustainable economic levels that break the cycle of poverty. With providing access to further education at its core, The Kasune Project takes a holistic approach in tandem, in its pursuit to empower young people to realise their full potential. The programmes meet recipients at their point of need, dealing with issues that might impact negatively on their personal journeys through education. High on the agenda is self care. There is no question that there can be no success where one’s mental health is in crisis. The work of The Kasune Project supports and highlights the importance of a robust and resilient mental state, as this is the gateway to any form of success.
                      Meanwhile, The Kasune Project also seeks to raise awareness about misunderstandings surrounding the relationship between poverty and access to further education in Zambia. “Picture this if you will... The Student Loans Company (which provides most student loans in the UK) can no longer provide student loans ever, at all.  Without this infrastructure that we have the privilege of accessing in the UK, there would be many young people deprived of the opportunity to pursue further education.  Often, in Zambia, being unable to access further education does not relate to being poverty stricken. It is simply because, like so many families in the UK, there is no disposable income to afford this extra opportunity.” This is the situation in Zambia, there is no such infracstructure and we have to call upon ourselves as citizens of Zambia, to see what it is we can do for our country. There's a social challenge.</p>
                    </Tab.Pane>

                    <Tab.Pane eventKey="third">
                      <h2>The Founder:</h2>
                      <h2>Ruth Kasune Banda</h2>
                      <img src={founderImg} alt="Header Img" style={{ display: 'block', marginLeft: 'auto',  marginRight: 'auto', width: '400px', height: '500px', borderRadius: '60px' }} />


                      <p>TheKasuneProject is the result of a relentless self challenge of my social responsibilities that started early 1995, just after my 23rd birthday. That is a long time to sit on a vision that has such urgency. My vision is simple. My call to action is simple. My prayer is truly a simple one: Let us work together, to ensure our youth have equitable access to quality education. Education should never be only for those who can afford it, it should be for everyone who hungers for it. Please join me in my work and with God before us, let us educate the youth of our beloved Zambia!</p>

                      <h2>The Inspiration:</h2>
                      <h2>Rodwin Chilikwela Kasune</h2>
                      <img src={inspirationImg} alt="Header Img" style={{ display: 'block', marginLeft: 'auto',  marginRight: 'auto', width: '400px', height: '500px', borderRadius: '60px' }} />


                      <p>TheKasuneProject is the result of a relentless self challenge of my social responsibilities that started early 1995, just after my 23rd birthday. That is a long time to sit on a vision that has such urgency. My vision is simple. My call to action is simple. My prayer is truly a simple one: Let us work together, to ensure our youth have equitable access to quality education. Education should never be only for those who can afford it, it should be for everyone who hungers for it. Please join me in my work and with God before us, let us educate the youth of our beloved Zambia!</p>

                      <h2>The Reason I Am:</h2>
                      <p>I say to the young people... "When I help you make sense of your life, my life makes sense". That is the reason I am. What is the reason you are?</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
