import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import meter4 from "../assets/img/meter4.svg";
import meter5 from "../assets/img/meter5.svg";
import meter6 from "../assets/img/meter6.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";


export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 1
    }
  };



  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Our Objectives</h2>
                        <p><br></br> </p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={meter1} alt="Image" />
                                <h5>To Provide</h5>
                                <p>funding for college and university education.</p>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="Image" />
                                <h5>To Provide </h5>
                                <p>funding for vocational training.</p>
                            </div>
                            <div className="item">
                                <img src={meter3} alt="Image" />
                                <h5>To Provide </h5>
                                <p>funding for extra tuition fees in grade 7, 9 & 12.</p>
                            </div>
                            <div className="item">
                                <img src={meter4} alt="Image" />
                                <h5>To Emphasize </h5>
                                <p>that education is the bedrock of a hopeful future.</p>
                            </div>
                            <div className="item">
                                <img src={meter5} alt="Image" />
                                <h5>To Emphasize</h5>
                                <p>that it takes a village to raise just one child.</p>
                            </div>
                            <div className="item">
                                <img src={meter6} alt="Image" />
                                <h5>To Emphasize</h5>
                                <p>that equitable access to quality education is a basic human right of every child.</p>
                            </div>
                            
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
  )
}
